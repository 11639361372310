import logo from './logo.svg';
import './App.css';
import Form from './form';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='flex header-logo'>
          <img src="/LOGO_ARBITRAJE.png" className="App-logo" alt="logo" />
          <img src="/logo1.jpg" className="App-second-logo" alt="logo" />
        </div>
        <Form />
      </header>
    </div>
  );
}

export default App;
