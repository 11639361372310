import React, { useEffect, useState } from 'react';
import { supabase } from './supabase';
import DataGrid from 'react-data-grid';
import * as XLSX from 'xlsx';
import 'react-data-grid/lib/styles.css';

const UserTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data, error } = await supabase.from('users').select();

        if (error) {
          console.error('Error fetching users:', error.message);
          return;
        }

        setUsers(data || []);
      } catch (error) {
        console.error('Error fetching users:', error.message);
      }
    };

    fetchUsers();
  }, []);

  const columns = [
    { key: 'full_name', name: 'Nombre Completo', minWidth: 200 },
    { key: 'dni', name: 'DNI', minWidth: 100 },
    { key: 'phone', name: 'Celular', minWidth: 150 },
    { key: 'email', name: 'Correo', minWidth: 200 },
    { key: 'position', name: 'Cargo', minWidth: 150 },
    { key: 'organization', name: 'Entidad', minWidth: 200 },
  ];

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');
    XLSX.writeFile(wb, 'usuarios.xlsx');
  };

  return (
    <div className="user-table-container">
      <h2>Tabla de Usuarios</h2>
      <DataGrid
        columns={columns}
        rows={users}
        headerRowHeight={40}
        className="rdg-light"
      />
      <button onClick={downloadExcel}>Descargar Excel</button>
    </div>
  );
};

export default UserTable;
